import React, { useEffect, useMemo, useRef, useState } from 'react';
import { once } from 'lodash';
import { Divider, Text, SectionNotification, TextButton, TextButtonPriority, TextField } from 'wix-ui-tpa/cssVars';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { TFunction, Trans, useTranslation } from '@wix/yoshi-flow-editor';
import { CheckoutProps } from '../../../../../types/common';
import { CheckoutStyledButton } from '../CheckoutStyledButton';
import { PurchaseLimitNotification } from '../PurchaseLimitNotification';
import { Action, StepSection } from '../StepSection';
import { StepAPI, StepProps } from '../types';
import { classes } from './CustomerInfoStep.st.css';

interface CustomerInfoStepProps extends StepProps {
  user: CheckoutProps['user'];
  userEmail: CheckoutProps['userEmail'];
  onContinueAsGuest: (email?: string) => void;
  guestEmail?: CheckoutProps['guestEmail'];
  guestEmailError: CheckoutProps['guestEmailError'];
  guestEmailConfirmed: CheckoutProps['guestEmailConfirmed'];
  guestCheckoutLoading: CheckoutProps['guestCheckoutLoading'];
  onEditGuestEmail: CheckoutProps['onEditGuestEmail'];
  onLoginClick(): void;
  onLogoutClick(): void;
  onEmailChange: (email: string) => void;
  purchaseLimitExceeded: boolean;
}

export const CustomerInfoStep: React.FC<CustomerInfoStepProps> = ({
  position,
  onApiSet,
  onCompleted,
  setActive,
  isPendingStep,
  isActiveStep,
  isCompletedStep,
  isLastStep,
  isNarrow,
  state,
  user,
  userEmail,
  onContinueAsGuest,
  guestEmail = '',
  guestEmailError,
  guestEmailConfirmed,
  guestCheckoutLoading,
  onEditGuestEmail,
  onLoginClick,
  onLogoutClick,
  onEmailChange,
  purchaseLimitExceeded,
}) => {
  const [guestEmailConfirmedLocal, setGuestEmailConfirmedLocal] = useState(guestEmailConfirmed);
  const [email, setEmail] = useState(guestEmail);
  const [error, setError] = useState(guestEmailError);
  const { t } = useTranslation();
  const isLoggedIn = user.loggedIn;
  const isGuestEmailValid = Boolean(guestEmail && guestEmailConfirmedLocal);
  const executionResolverRef = useRef<(() => void) | null>(null);

  const onEditGuestEmailLocal = useMemo(() => {
    return once(onEditGuestEmail);
  }, [onEditGuestEmail, guestEmailConfirmed]);

  useEffect(() => {
    setGuestEmailConfirmedLocal(guestEmailConfirmed);
  }, [guestEmailConfirmed]);

  useEffect(() => {
    if (guestEmailError) {
      executionResolverRef.current?.();
    }
  }, [guestEmailError]);

  useEffect(() => {
    if (isGuestEmailValid) {
      executionResolverRef.current?.();
    }
  }, [isGuestEmailValid]);

  useEffect(() => {
    setError(guestEmailError);
  }, [guestEmailError]);

  const onChangeEmail = (value: string) => {
    setError(undefined);
    setEmail(value);
    onEmailChange(value);
    onEditGuestEmailLocal();
  };

  const api: StepAPI = useMemo(
    () => ({
      execute: async () => {
        const promise = new Promise<void>((resolve) => {
          executionResolverRef.current = resolve;
        });
        onContinueAsGuest(email);
        // delaying execute until onContinueAsGuest has updated the state
        return promise;
      },
      isValid: () => isLoggedIn || isGuestEmailValid,
    }),
    [email, onContinueAsGuest, isLoggedIn, isGuestEmailValid],
  );

  useEffect(() => {
    onApiSet(api);
  }, [api]);

  useEffect(() => {
    if (isActiveStep && (isLoggedIn || isGuestEmailValid) && !purchaseLimitExceeded) {
      onCompleted();
    }
  }, [isActiveStep, isLoggedIn, isGuestEmailValid, purchaseLimitExceeded, onCompleted]);

  const purchaseLimitNotification = purchaseLimitExceeded && (
    <div className={classes.purchaseLimitWrapper}>
      <PurchaseLimitNotification />
    </div>
  );

  return (
    <StepSection
      dataHook="guest-checkout-login"
      position={position}
      title={t('payment.checkout.customer-details')}
      action={
        isLoggedIn ? (
          <Action dataHook="member-section-log-out" onClick={() => onLogoutClick()}>
            {t('payment.checkout-steps.sign-up.logout-button')}
          </Action>
        ) : state === 'completed' ? (
          <Action
            dataHook="guest-checkout-edit-email"
            onClick={() => {
              onEditGuestEmail();
              setGuestEmailConfirmedLocal(false);
              setActive();
            }}
          >
            {t('payment.guest-checkout.edit')}
          </Action>
        ) : undefined
      }
      state={state}
    >
      <div className={classes.root}>
        {isLoggedIn ? (
          <>
            <Text className={classes.memberInfo} tagName="p" data-hook="member-section-info">
              {t('payment.checkout-steps.sign-up.body', { identity: userEmail })}
            </Text>
            {purchaseLimitNotification}
          </>
        ) : state === 'completed' ? (
          <>
            <Text className={classes.confirmedEmail}>{guestEmail}</Text>
            {purchaseLimitNotification}
          </>
        ) : (
          <>
            <SectionNotification type="default">
              <SectionNotification.Text className={classes.notificationText}>
                <Trans
                  i18nKey="payment.guest-checkout.log-in"
                  default="Already have an account? <button>Log in</button> for a faster checkout."
                  components={{
                    button: (
                      <TextButton
                        className={classes.loginTextButton}
                        priority={TextButtonPriority.link}
                        onClick={() => onLoginClick()}
                      />
                    ),
                  }}
                />
              </SectionNotification.Text>
            </SectionNotification>
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} />
              <Text className={classes.dividerText}>{t('payment.guest-checkout.continue-as-guest')}</Text>
              <Divider className={classes.divider} />
            </div>
            <TextField
              label={t('payment.guest-checkout.email') + '*'}
              data-hook={VIEWER_HOOKS.CHECKOUT_GUEST_EMAIL_INPUT}
              required
              inputMode="email"
              value={email}
              onChange={(e) => {
                onChangeEmail(e.target.value);
              }}
              newErrorMessage
              error={!!error}
              errorMessage={getEmailErrorMessage(error, t)}
              className={classes.textField}
            />
            {purchaseLimitExceeded && (
              <div className={classes.purchaseLimitWrapper}>
                <PurchaseLimitNotification />
              </div>
            )}
            {!isLastStep || isNarrow ? (
              <CheckoutStyledButton
                dataHook={VIEWER_HOOKS.CHECKOUT_GUEST_CONTINUE_BUTTON}
                loading={guestCheckoutLoading}
                disabled={guestCheckoutLoading || purchaseLimitExceeded}
                className={classes.continueButton}
                label={t('payment.guest-checkout.continue')}
                onClick={() => onContinueAsGuest(email)}
              />
            ) : null}
          </>
        )}
      </div>
    </StepSection>
  );
};

const getEmailErrorMessage = (emailError: Required<CheckoutProps['guestEmailError']>, t: TFunction) => {
  switch (emailError) {
    case 'required':
      return t('payment.guest-checkout.email-required');
    case 'invalid':
      return t('payment.guest-checkout.email-invalid');
    case 'already-purchased':
      return t('payment.guest-checkout.already-purchased');
    default:
      return '';
  }
};
