import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { AppWidgetProps } from '../../../types/common';
import Checkout from '../../PackagePicker/Widget/Checkout';
import { CheckoutLoader } from '../../PackagePicker/Widget/CheckoutLoader';

const CheckoutWidget: React.FC<AppWidgetProps> = (props) => {
  const [isCheckoutComponentLoaded, setIsCheckoutComponentLoaded] = React.useState(false);
  const { isSSR } = useEnvironment();
  return (
    <CheckoutLoader loading={(!props.isCheckoutDataInitialized || !isCheckoutComponentLoaded) && !isSSR}>
      <Checkout
        {...props}
        siteStyles={props.host.style}
        viewMode={props.host.viewMode}
        onLoad={() => setIsCheckoutComponentLoaded(true)}
      />
    </CheckoutLoader>
  );
};

export default CheckoutWidget;
