import React from 'react';
import { SectionNotification } from 'wix-ui-tpa';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './PurchaseLimitNotification.st.css';

export const PurchaseLimitNotification: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionNotification
      type="error"
      className={classes.root}
      data-hook={VIEWER_HOOKS.CHECKOUT_PURCHASE_LIMIT_NOTIFICATION}
    >
      <SectionNotification.Text>{t('pp.checkout-purchase-limit-exceeded')}</SectionNotification.Text>
    </SectionNotification>
  );
};
