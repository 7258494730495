import { useCallback, useMemo } from 'react';
import { Benefit } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { Order, SpannedPrice } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { useCurrencyFormatter, useEnvironment, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { useLocale } from '../../../../../hooks/useLocale';
import { getFormattedPriceData } from '../../../../../hooks/usePrice';
import { CheckoutSummaryPresenter, FormatPriceProps } from './presenters';

export const usePresenter = (plan: PublicPlan, prices: SpannedPrice[], benefits: Benefit[], order?: Order) => {
  const { language } = useEnvironment();
  const { locale } = useLocale();
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  const shouldUseCurrencyFormatter = experiments.enabled(TPA_EXPERIMENTS.USE_CURRENCY_FORMATTER);

  const simplePriceFormatter = useCallback(
    ({ amount, currency }: FormatPriceProps) => {
      if (amount) {
        if (currency) {
          return parseFloat(amount).toLocaleString(locale ?? language, {
            minimumFractionDigits: typeof amount === 'string' && amount.includes('.') ? undefined : 0,
            style: 'currency',
            currency,
          });
        }
        return amount;
      }
    },
    [language, locale],
  );

  const priceFormatter = useCallback(
    ({ amount, currency }: FormatPriceProps) => {
      if (shouldUseCurrencyFormatter) {
        const { fullPrice } = getFormattedPriceData({
          createCurrencyFormatter: useCurrencyFormatter,
          locale,
          value: amount,
          currency,
        });

        return fullPrice;
      } else {
        return simplePriceFormatter({ amount, currency });
      }
    },
    [shouldUseCurrencyFormatter, locale, simplePriceFormatter],
  );

  const presenter = useMemo(
    () => new CheckoutSummaryPresenter(plan, prices, benefits, locale ?? language, t, priceFormatter, order),
    [benefits, language, locale, order, plan, priceFormatter, prices, t],
  );

  return presenter;
};
